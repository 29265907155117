import { postData } from '../utils/http';
import { useCallback, useEffect } from 'react';
import { useFetchData } from './useFetchData';
import { trackUser } from '../utils/rudderstack';

export function usePatientUser(username: string | null) {
  const { data, isError } = useFetchData<Record<string, string>>(
    username && `/patient/${username}/user`
  );

  const setPassword = useCallback(
    (password: string, confirmationCode: string | null) => {
      return postData(`/patient/${username}/password`, {
        password,
        confirmationCode,
      });
    },
    [username]
  );

  useEffect(() => {
    if (username && data) {
      trackUser(username, data);
    }
  }, [username, data]);

  return {
    user: data,
    setPassword,
    isError,
  };
}
