const supportedLanguages = [
  'ar',
  'ca',
  'da',
  'de',
  'en_GB',
  'en',
  'es_419',
  'es_ES',
  'fr',
  'it',
  'nl',
  'pt_BR',
  'sv',
  'tr',
  'zh_CN',
  'zh_TW',
] as const;

export default supportedLanguages;

export type SupportedLanguageType = (typeof supportedLanguages)[number];

export const fallbackLanguages: Record<string, SupportedLanguageType> = {
  es: 'es_419',
  pt: 'pt_BR',
  zh: 'zh_CN',
};

const RTL_LOCALES: Record<string, boolean> = {
  ar: true,
  // Add any other Right to left languge here
};

export function isRtlLanguage(language: string) {
  return RTL_LOCALES[language] || false;
}
