import { FormattedMessage } from 'react-intl';
import styles from './CreatePasswordPage.module.scss';

export function CreatePasswordErrorFallback({ error }: { error?: Error }) {
  if (error?.message === 'user not found') {
    return (
      <div className={styles.fallback}>
        <h2>
          <FormattedMessage
            id="create-password-page.user-not-found"
            defaultMessage="Sorry, we could not find your account."
          />
        </h2>
        <p>
          <FormattedMessage
            id="create-password-page.try-again"
            defaultMessage="Please try again later or ask your clinic to invite you again."
          />
        </p>
      </div>
    );
  }

  return (
    <h2>
      <FormattedMessage
        id="create-password-page.generic-error"
        defaultMessage="Could not fetch user"
      />
    </h2>
  );
}
