import { useIntl } from 'react-intl';
import styles from './DownloadAppLinks.module.scss';

export function DownloadAppLinks() {
  const intl = useIntl();
  const locale = intl.locale;

  return (
    <div className={styles.stores}>
      <a
        href="https://apps.apple.com/us/app/allurion/id1114672166"
        className={styles.appleStoreLink}
      >
        <img
          src={getAppleAppStoreBadgeUrl(locale)}
          alt={intl.formatMessage({
            id: 'create-password-page.app-store-download',
            defaultMessage: 'Download on the App Store',
          })}
        />
      </a>

      <a href="https://play.google.com/store/apps/details?id=com.allurion">
        <img
          src={getGooglePlayBadgeUrl(locale)}
          alt={intl.formatMessage({
            id: 'create-password-page.google-play-download',
            defaultMessage: 'Get it on Google Play',
          })}
        />
      </a>
    </div>
  );
}

function getGooglePlayBadgeUrl(locale: string): string {
  let googlePlayLocale = locale.toLowerCase();

  if (!googlePlayLocale.startsWith('zh')) {
    googlePlayLocale = googlePlayLocale.substring(0, 2);
  }

  return `https://play.google.com/intl/en_us/badges/static/images/badges/${googlePlayLocale}_badge_web_generic.png`;
}

function getAppleAppStoreBadgeUrl(locale: string): string {
  const appleAppStoreLocale = locale.toLowerCase();

  return `https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/${appleAppStoreLocale}?size=250x83`;
}
