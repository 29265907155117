import { forwardRef, useCallback, useState } from 'react';

import eyeOpenIcon from '../../eye-open-icon.svg';
import eyeClosedIcon from '../../eye-closed-icon.svg';
import styles from './PasswordInput.module.scss';

export const PasswordInput = forwardRef((props: any, ref) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleIsVisible = useCallback(() => {
    setIsVisible((state) => !state);
  }, []);

  return (
    <div className={styles.container}>
      <input
        {...props}
        ref={ref}
        type={isVisible ? 'text' : 'password'}
        data-testid="password-input"
      />
      <button type="button" onClick={toggleIsVisible}>
        <img
          src={isVisible ? eyeClosedIcon : eyeOpenIcon}
          alt={isVisible ? 'hide password' : 'show password'}
        />
      </button>
    </div>
  );
});

PasswordInput.displayName = 'PasswordInput';
