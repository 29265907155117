import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { CreatePasswordPage } from './CreatePasswordPage/CreatePasswordPage';
import { Layout } from './shared/components/Layout';
import { HomePage } from './HomePage/HomePage';
import { NotFoundPage } from './NotFoundPage/NotFoundPage';
import { LocaleProvider } from './shared/intl/LocaleProvider';
import { GenericErrorFallback } from './shared/components/GenericErrorFallback';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export default function App() {
  return (
    <LocaleProvider>
      <Sentry.ErrorBoundary fallback={GenericErrorFallback}>
        <BrowserRouter>
          <SentryRoutes>
            <Route path="/" element={<Layout />}>
              <Route index element={<HomePage />} />
              <Route path="create-password/*" element={<CreatePasswordPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </SentryRoutes>
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </LocaleProvider>
  );
}
