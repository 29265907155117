import supportedLanguages, { SupportedLanguageType } from './supported-languages';

import ar from '../../lang/ar.json';
import ca from '../../lang/ca.json';
import da from '../../lang/da.json';
import de from '../../lang/de.json';
import en from '../../lang/en.json';
import en_GB from '../../lang/en_GB.json';
import es_419 from '../../lang/es_419.json';
import es_ES from '../../lang/es_ES.json';
import fr from '../../lang/fr.json';
import it from '../../lang/it.json';
import nl from '../../lang/nl.json';
import pt_BR from '../../lang/pt_BR.json';
import sv from '../../lang/sv.json';
import tr from '../../lang/tr.json';
import zh_CN from '../../lang/zh_CN.json';
import zh_TW from '../../lang/zh_TW.json';

const supportedTranslationsByLanguage: {
  [key in SupportedLanguageType]: Record<string, string>;
} = {
  ar: ar,
  ca: ca,
  da: da,
  de: de,
  en_GB: en_GB,
  en: en,
  es_419: es_419,
  es_ES: es_ES,
  fr: fr,
  it: it,
  nl: nl,
  pt_BR: pt_BR,
  sv: sv,
  tr: tr,
  zh_CN: zh_CN,
  zh_TW: zh_TW,
};

export function getSupportedTranslationsByLanguage() {
  validateTranslationFiles();

  return supportedTranslationsByLanguage;
}

function validateTranslationFiles() {
  for (const lang of supportedLanguages) {
    if (!supportedTranslationsByLanguage[lang]) {
      throw new Error(
        `Missing translations json file for lang ${lang}. Check "app/src/shared/intl/translations.ts"`
      );
    }
  }
}
