import { PropsWithChildren, useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import * as Sentry from '@sentry/react';

import supportedLanguages, {
  fallbackLanguages,
  isRtlLanguage,
  SupportedLanguageType,
} from './supported-languages';
import { getSupportedTranslationsByLanguage } from './translations';

export function LocaleProvider({ children }: PropsWithChildren<any>) {
  const locale = navigator.language;

  const messages = useMemo(() => {
    try {
      const localizeLocale = convertBrowserLocale(locale);
      const translations = getSupportedTranslationsByLanguage();
      return translations[localizeLocale];
    } catch (err) {
      Sentry.captureException(err);
    }
  }, [locale]);

  useEffect(() => {
    const documentElement = document.documentElement;
    if (documentElement.getAttribute('lang') !== locale) {
      documentElement.setAttribute('lang', locale);
    }

    if (!documentElement.getAttribute('dir')) {
      documentElement.setAttribute('dir', isRtlLanguage(locale) ? 'rtl' : 'ltr');
    }

    if (!messages) {
      Sentry.captureException(new Error(`Missing translations for locale: ${locale}`));
    }
  }, [locale, messages]);

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}

function convertBrowserLocale(locale: string): SupportedLanguageType {
  let language = locale.replace('-', '_');

  if (supportedLanguages.includes(language as any)) {
    return language as SupportedLanguageType;
  }

  const commonLanguage = language.substring(0, 2);

  if (supportedLanguages.includes(commonLanguage as any)) {
    return commonLanguage as SupportedLanguageType;
  }

  const fallbackLanguage = fallbackLanguages[commonLanguage];

  if (fallbackLanguage) {
    return fallbackLanguage;
  }

  throw new Error(`Unsupported locale: ${locale}`);
}
